import React from 'react';
import App, { createUrl } from 'next/app';
import Head from 'next/head';
import { hydrate, injectGlobal } from 'react-emotion';
import { createPageWithOIDCAuth } from '@shared/utils/soya-auth';
import {
  QueryClientProvider,
  QueryClient,
  QueryCache,
} from '@tanstack/react-query';

import '../src/@shared/styles/global.css';
import '@traveloka/ckeditor5-custom-txt-build/dist/ckeditor.css';

// commented because it cause web from yarn dev and yarn build to have different ccss
// if somehow cannot navigate between page please uncomment this
// import '../public/static/empty.css';

// Adds server generated styles to emotion cache.
// '__NEXT_DATA__.ids' is set in '_document.js'
if (typeof window !== 'undefined') {
  hydrate(window.__NEXT_DATA__.ids);
}

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  .c-datetimepicker input[readonly] {
    background-color: #ffffff;
    opacity: 1;
  }

  .c-datetimepicker input[disabled] {
    background-color: #f5f6f8;
    opacity: 0.7;
  }
`;

const Page = ({ Component, pageProps, router, ...props }) => (
  <Component {...props} {...pageProps} url={createUrl(router)} />
);
// WORKAROUND
// Remove this and use soya-components' default when it's OIDCAuthorizeView
// is updated to use LocalStorage instead Cookies
const BasePage = createPageWithOIDCAuth()(Page);
const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default class extends App {
  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {};
    const initialProps = await BasePage.getInitialProps(ctx);
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ...ctx, ...initialProps });
    }
    return { ...initialProps, pageProps };
  }

  render() {
    return (
      <React.Fragment>
        <Head>
          <title>{'TRIP Extranet'}</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Head>

        <QueryClientProvider client={queryClient}>
          <BasePage {...this.props} />
        </QueryClientProvider>
      </React.Fragment>
    );
  }
}
